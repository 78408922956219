<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
    <PopupPayout ref="popupPayoutRef" :payout="currentPayout"  />
    <b-row>
    <div :class="{'col-md-8':window.innerWidth<=760 || window.innerWidth>1024,'col-md-12':window.innerWidth>760 && window.innerWidth<=1024}">
  <b-card class="h-90" title="Historique des paiements" v-if="activePayment"> 

    <div class="round-element black">
      <img src='@/assets/images/card.svg' class="icon" width="70" />
    </div>

    <b-dropdown pill variant="outline-secondary" class="top-button top-right" :text="currentDateFilter.text">
      <b-dropdown-item v-if="currentDateFilter.value!='week'" @click="selectDateFilter('week','This week')">This week</b-dropdown-item>
      <b-dropdown-item v-if="currentDateFilter.value!='month'" @click="selectDateFilter('month','This month')">This month</b-dropdown-item>
      <b-dropdown-item v-if="currentDateFilter.value!='year'" @click="selectDateFilter('year','This year')">This year</b-dropdown-item>
    </b-dropdown>

    
  <vue-good-table
      class="left-align"
      @on-row-click="(params) => openPopup(params.row)"
    
        v-if="!loading"
          :columns="window.innerWidth > 768 && window.innerWidth<=1024 ? columnsipad : window.innerWidth > 768 ? columns : columnsmob"
          :rows="filteredPayouts"
          :pagination-options="{
            enabled: false,
           
          }"
        >
        
          <template 
            slot="table-row"
            slot-scope="props"
            
          >
          <span
          v-if="props.column.field === 'amount'"
          class="text-nowrap"
        >
         
            <span class="text-nowrap">{{(props.row.amount/100).toFixed(2)}}€</span>
        
        </span>

        <span
          v-else-if="props.column.field === 'livemode'"
          class="text-nowrap"
        >
         <div v-if="window.innerWidth > 768">
          <span class="pastille green"></span> Réussi
         </div>
         <div v-else>
          <span class="pastille green"></span>
         </div>
        
        </span>


        <span
          v-else-if="props.column.field === 'object'"
          class="text-nowrap"
        >
         
          <span v-if="props.formattedRow[props.column.field]=='transfer'">Virement</span>
          <span v-else>Autre</span>
        
        </span>

        <span
          v-else-if="props.column.field === 'created'"
          class="text-nowrap"
        >
         
            <span class="text-nowrap">{{convertTimestamp(props.row.created)}}</span>
        
        </span>

        <span v-else-if="props.column.field === 'action'">

      <!-- <img src='@/assets/images/edit.svg'  @click="$router.push({name:'editPromotion', params: { id: props.row.id }})" class="icon-action" width="70" /> -->
        <img src='@/assets/images/edit.svg'  @click="openPopup(props.row)" class="icon-action" width="70" />

        
        </span>
            
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        
        </vue-good-table>
      </b-card>
    </div>
      <div :class="{'col-md-4':window.innerWidth<=760 || window.innerWidth>1024,'col-md-12':window.innerWidth>760 && window.innerWidth<=1024}">
        
        <b-row v-if="window.innerWidth>760 && window.innerWidth<=1024">
          <div class="col-md-6 pr-1">
          <b-card v-if="comissionLoaded" title="Mon montant" >
            
            <div class="round-element blue">
              <img src='@/assets/images/profile.svg' class="icon" width="70" />
            </div>
            <b-button class="top-button black top-button2" @click="sendPaymentRequest">Demander le payout</b-button>
              <div class="col-nb numcontent">
              <h2 class="big-nb"><span class="sign">€</span>{{ (totalComission - totalPayouts).toLocaleString()  }}<span class="little-waiting">({{ totalComissionWaiting }}€ en attente)</span></h2> 
            </div>
            
          </b-card>
        </div>

        <b-card  title="Paiements en attente"  :class="{'col-md-6':window.innerWidth>760 && window.innerWidth<=1024}">
          
          <div class="round-element orange">
            <img src='@/assets/images/sablier.svg' class="icon" width="70" />
          </div>
          <div v-if="payout_requests.length==0">
          
            <br /><br />
            Aucun paiement en attente pour le moment
            <br /><br />
          </div>
          <table v-else class="table">
            <tr>
              <th>Demande du</th>
              <th>Montant</th>
              <th>Méthode de paiement</th>
            </tr>
            <tr v-for="payout_request in payout_requests">
              <td>{{payout_request.created_at}}</td>
              <td>{{payout_request.amount}}€</td>
              <td>{{payout_request.payout_method}}</td>
            </tr>
          </table>
        </b-card>
      </b-row>
      <template v-else>
        <b-card v-if="comissionLoaded" title="Mon montant"  :class="{'col-md-6':window.innerWidth>760 && window.innerWidth<=1024}">
          
          <div class="round-element blue">
            <img src='@/assets/images/profile.svg' class="icon" width="70" />
          </div>
          <b-button class="top-button black top-button2" @click="sendPaymentRequest">Demander le payout</b-button>
            <div class="col-nb numcontent">
            <h2 class="big-nb"><span class="sign">€</span>{{ (totalComission - totalPayouts).toLocaleString()  }}<span class="little-waiting">({{ totalComissionWaiting }}€ en attente)</span></h2> 
          </div>
          
        </b-card>

        <b-card  title="Paiements en attente"  :class="{'col-md-6':window.innerWidth>760 && window.innerWidth<=1024}">
          
          <div class="round-element orange">
            <img src='@/assets/images/sablier.svg' class="icon" width="70" />
          </div>
          <div v-if="payout_requests.length==0">
          
            <br /><br />
            Aucun paiement en attente pour le moment
            <br /><br />
          </div>
          <table v-else class="table">
            <tr>
              <th>Demande du</th>
              <th>Montant</th>
              <th>Méthode de paiement</th>
            </tr>
            <tr v-for="payout_request in payout_requests">
              <td>{{payout_request.created_at}}</td>
              <td>{{payout_request.amount}}€</td>
              <td>{{payout_request.payout_method}}</td>
            </tr>
          </table>
        </b-card>
      </template>
      </div>
    </b-row>
</b-overlay>
</template>

<script>
import PopupPayout from './PopupPayout.vue'
import {BModal, BRow, BButton, VBModal,BOverlay, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest, Woocommerce,Auth} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueApexCharts from 'vue-apexcharts'
export default {
  components: {
    BCard,
    PopupPayout,
    BCardText,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BRow,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    VueApexCharts,
    BDropdownItem,
    BModal,
    BOverlay,
    BButton
  },
  data() {
    return {
      orders:[],
      comissions:[],
      totalComissionWaiting:0,
      window:window,
      payout_requests:[],
      currentDateFilter:{text:'This week',value:'week'},
      pageLength: 10,
      dir: false,
      filteredPayouts:[],
      payouts:null,
      
      comissionLoaded:false,
      totalComission:0,
      totalPayouts:0,
      activePayment:false,
      loading:true,
      glasses:[],
      totalRows:0,
      currentPage:1,
      columns: [
        
        {
          label: 'Date de paiement',
          field: 'created',
        },
        {
          label: 'Montant payé',
          field: 'amount',
          tdClass: 'text-right',
        },
        {
          label: 'Référence de transaction',
          field: 'id',
        },
        {
          label: 'Mode de paiement',
          field: 'object',
          tdClass:"capitalize"
        },
      
        {
          label: 'Statut du paiement',
          field: 'livemode',
          
        },
        
      ],
      columnsipad: [
        
        {
          label: 'Date',
          field: 'created',
        },
        {
          label: 'Montant',
          field: 'amount',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Référence de transaction',
          field: 'id',
        },
        
      
        {
          label: 'Statut',
          field: 'livemode',
          tdClass: 'text-center',
          thClass: 'text-center',
          
        },
        {
          label: 'Action',
          field: 'action',
          tdClass: 'text-center',
          thClass: 'text-center',
        }
        
      ],
      
      columnsmob: [
        
        {
          label: 'Date',
          field: 'created',
        },
        {
          label: 'Montant',
          field: 'amount',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        
      
        {
          label: 'Statut',
          field: 'livemode',
          tdClass: 'text-center',
          thClass: 'text-center',
          
        },
        {
          label: 'Action',
          field: 'action',
          tdClass: 'text-center',
          thClass: 'text-center',
        }
        
      ],
      currentPromo:null,
      selectedItem:null,
      
      rows: [],
      searchTerm: '',
      options: {
        chart: {
          id: 'vuechart-example'
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998]
        }
      },
      series: [{
        name: 'series-1',
        data: [30, 40, 45, 50, 49, 60, 70, 91]
      }],
      totalComission:0,
      currentPayout:null

    }
  },
  methods:{
    
    convertTimestamp(ts){
      let date=new Date(ts * 1000)

      
      return date.toLocaleDateString('fr-FR')
    },
    selectDateFilter(value,text)
    {
      this.currentDateFilter.text=text
      this.currentDateFilter.value=value
      this.filterComissionsByDate()
    },
    filterComissionsByDate(){

      let newFilteredPayouts=[]
      
      let date_end=new Date()
      let date_begin
      if(this.currentDateFilter.value=='week')
      {
        let date = new Date()
        date.setDate(date.getDate() - 7)
        date_begin = date

      }
      if(this.currentDateFilter.value=='month')
      {
        let date = new Date()
        date.setDate(date.getDate() - 30) 
        date_begin = date

      }
      if(this.currentDateFilter.value=='year')
      {
        let date = new Date()
        date.setDate(date.getDate() - 365) 
        date_begin = date
      }


    
          
      for(let i=0;i<this.payouts.length;i++)
      {
        if(this.payouts[i].created!=null)
        {
        
          let [d, m, y] = this.convertTimestamp(this.payouts[i].created).split(/-|\//); // splits "26-02-2012" or "26/02/2012"
          let date = new Date(y, m - 1, d);
          

          // On verifie si c'est compris entre les deux dates



          if(date.getTime()>=date_begin.getTime() && date.getTime()<=date_end.getTime())
          {
            newFilteredPayouts.push(this.payouts[i])
          }

        }
      }

      this.filteredPayouts=newFilteredPayouts

     
      //this.blackGraphLoading=false
    },
    
    openPopup(payout){
      this.currentPayout=payout
      if(this.$refs.popupPayoutRef)
      {
        this.$refs.popupPayoutRef.openPopup()
      }
     
    },
    convertTimestamp(ts){
      let date=new Date(ts * 1000)

      
      return date.toLocaleDateString('fr-FR')
    },
    async getPayoutRequests(){
      let payout_requests=await APIRequest.list_with_request('payout-requests', '?user='+localStorage.id_user_senseye_auth+'&limit=1000')
      
      if(payout_requests[0].length)
      {
        this.payout_requests=payout_requests[0]
      }
      console.log(this.payout_requests)
     
    },
    calculateRealCom()
    {
      let totalCom=0
      let totalComWaiting=0
      for(let i=0;i<this.comissions.length;i++)
      {
        if(this.comissions[i].date)
        {
          let currentDate = new Date();

          // Add 7 days to the current date
          let beforeDate = new Date(currentDate);
          beforeDate.setDate(currentDate.getDate() - 14);

     
          let [day, month, year] = this.comissions[i].date.split('/'); // Split the date string
          let timestamp = new Date(`${year}-${month}-${day}`); // Convert to timestamp
       
          if(timestamp.getTime()<beforeDate.getTime())
          {
            totalCom+=this.comissions[i].amount
          }
          else
          {
            totalComWaiting+=this.comissions[i].amount
          }

        }
        else 
        {
          totalCom+=this.comissions[i].amount
        }
        
      }
      this.totalComission=totalCom
      this.totalComissionWaiting=totalComWaiting
    },
    async sendPaymentRequest()
    {
      let params={
        user:{id:localStorage.id_user_senseye_auth},
        amount:this.totalComission-this.totalPayouts,
        payout_method:'Virement bancaire'
      }
      await APIRequest.create('payout-requests',params)

      await this.getPayoutRequests()
      //await APIRequest.send_payment_request(localStorage.id_user_senseye_auth,this.totalComission-this.totalPayouts)
      this.$toast({
          component: ToastificationContent,
          props: {
              title: 'La demande de paiement a été envoyée !',
              icon: 'CheckIcon',
              variant: 'success',
          },
      })
    }
  },
  watch:{
    
  
   
  },
  async mounted()
  {
  

    let user_data=await Auth.get_user_with_id(localStorage.id_user_senseye_auth)
   

    if(user_data.stripe_id)
    {
      this.activePayment=true
      let payouts=await APIRequest.get_payouts_stripe(user_data.stripe_id)

      this.totalPayouts=0
      this.payouts=payouts.data
      for(let i=0;i<payouts.data.length;i++)
      {
      this.totalPayouts=this.totalPayouts+payouts.data[i].amount/100
      }
      let scans=await APIRequest.get_comissions(localStorage.id_user_senseye_auth)
      //this.totalComission=scans.total
      this.comissions=scans.data
      this.calculateRealCom()
      this.comissionLoaded=true
      await this.filterComissionsByDate()
    }

    
      
    await this.getPayoutRequests()
   

    // on get tous les produits assignés

    // let scans=await APIRequest.list_with_request('glasses', '?creator_owner='+localStorage.id_user_senseye_auth)
    // //let scans=await APIRequest.list('glasses')
    // this.glasses=scans[0]

    // // pour chaque produit on get les orders
    // this.orders=[]
    // for(let i=0;i<this.glasses.length;i++)
    // {
      
    //   let request='?model='+this.glasses[i].id+'&limit=1000'
    //   let orders=await APIRequest.list_with_request('order-items', request)

     
    //   orders=orders[0]

    //   let orders_final=[]

    //    // on vérifie si elle existe deja
    //   for(let j=0;j<orders.length;j++)
    //   {
    //     let exists=false

    //     for(let p=0;p<this.orders.length;p++)
    //     {
    //       if(orders[j].id==this.orders[p].id)
    //       {
    //         exists=true
    //       }
    //     }
    //     if(exists==false)
    //     {
    //       orders_final.push(orders[j])
    //       this.orders.push(orders[j])
    //     }
    //   }

    //   //this.orders.push(orders_final)


    // }

    // console.log('ok part 1')

    // // pour chaque order on recup la/les lignes concernées par un produit
    


    // for(let i=0;i<this.orders.length;i++)
    // {

    //   console.log(this.orders[i])
    //     // on calcule la comission pour la ligne et on l'ajoute au total
    //       for(let j=0;j<this.glasses.length;j++)
    //       {
    //         if(this.glasses[j].id==this.orders[i].glass.id)
    //         {
    //           if(this.glasses[j].comission)
    //           {
              
    //             this.totalComission=this.totalComission+((this.orders[i].amount/100)*this.glasses[j].comission)
    //           }
    //         }
       
          
    //   }
    // }
      
    // for(let i=0;i<this.orders.length;i++)
    // {
    //   console.log('test order')
    //   console.log(this.orders)
    //   let order_detail=await APIRequest.detail('orders', this.orders[i].id)
    //   if(order_detail.order_items)

    //   {
    //     for(let p=0;p<order_detail.order_items.length;p++)
    //     {
    //       for(let j=0;j<this.glasses.length;j++)
    //       {
    //         if(this.glasses[j].id==order_detail.order_items[p].glass.id)
    //         {

    //           // on calcule la comission pour la ligne et on l'ajoute au total

    //           if(order_detail.order_items[p].glass.comission)
    //           {
    //             this.totalComission=this.totalComission+((order_detail.order_items[p].amount/100)*order_detail.order_items[p].glass.comission)
    //           }
              
    //         }
    //       }
    //     }
    //   }
      

    // }



    // on recup tous les payouts effectués

    // on calcule le solde total du montant du


    this.loading=false
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
.top-button
{
  left:250px;
}
.top-right 
{
  margin-right:80px;
}
body .text-right
{
  text-align: right !important;
}
body .capitalize
{
  text-transform: capitalize;
}
body .text-center
{
  text-align: center !important;
}
.h-90
{
  height: 97%;
}
@media screen and (max-width:768px) {
  th,td {
        font-size: 11px;
    }
    .col-md-4
    {
      margin-top:0.5rem;
    }
}
@media screen and (min-width:1024px) and (max-width:1630px) {
 .top-button.black
 {
  top:0;
  margin-top:10px;
  position: relative;
  left:0;
 }
 th,td {
        font-size: 13px;
        padding:5px 10px !important;
    }
}
@media screen and (min-width:768px) and (max-width:1024px) {
  th,td {
        font-size: 11px;
        padding:5px 10px !important;
    }
    .pr-1
    {
      padding-left:0 !important;
    }
    .col-md-12 .row
    {
      margin-top:0.5rem;
    }
}
</style>
